<template>
  <div
    class="flex items-center text-center separator"
    :class="[color, padding]"
  >
    <template v-if="word">
      {{ word }}
    </template>
  </div>
</template>

<script>
export default {
  props: {
    word: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: 'py-8'
    },
    color: {
      type: String,
      default: 'text-gray-300'
    }
  }
}
</script>

<style scoped>
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid;
  border-color: rgb(209 213 219);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
